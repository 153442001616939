import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  {
    path: '/',
    name: 'list',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/list.vue'),
    meta: {
      title: '首页'
    }
  },
  {
    path: '/order',
    name: 'order',
    component: () => import( '../views/order.vue'),
    meta: {
      title: '订单'
    }
  },
  // {
  //   path: '/haha',
  //   name: 'haha',
  //   component: () => import('../views/haha.vue'),
  //   meta: {
  //     title: '订单'
  //   }
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
